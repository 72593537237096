import React, { useReducer } from 'react'
import BuyerContext from './buyerContext'
import BuyerReducer from './buyerReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { RESPONSE_STATUS, CLEAR_RESPONSE } from './buyerTypes'

const BuyerState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(BuyerReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    // Bid Confirm
    const bidConfirm = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'confirm', formData, '', 'bid')])
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    // Bid Confirm
    const preBidConfirm = async (formData) => {
        try {
            await Promise.all([apiCall('post', 'preBidConfirm', formData, '', 'bid')])
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    // Bid Cancel
    const bidCancel = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'bidCancel', formData, '', 'bid')])
            resp.commonResponse(res.data, 'bidCancel')
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    // TTWBid Confirm
    const ttwBidConfirm = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'confirm', formData, '', 'ttwbid')])
        } catch (err) {
            resp.commonErrorResponse('bid')
        }
    }

    const getFutureProxyList = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'future_proxy', formData, '', 'ttwbid'),
            ])
            resp.commonResponse(res.data, 'future_proxy')
        } catch (err) {
            resp.commonErrorResponse('future_proxy')
        }
    }

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <BuyerContext.Provider
            value={{
                responseStatus: state.responseStatus,
                clearResponse,
                preBidConfirm,
                bidConfirm,
                bidCancel,
                ttwBidConfirm,
                getFutureProxyList,
            }}
        >
            {props.children}
        </BuyerContext.Provider>
    )
}

export default BuyerState
