import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { Button, Dialog } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { mapData, removeHTMLTags } from '../../../../product/common/components'
import { useFormik } from 'formik'
// import Loaders from '@/custom/components/molecules/Loaders'
import * as Yup from 'yup'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'
import {
    initialFunction,
    validationFunction,
} from '../../../../product/components/species/dynamic/common/FieldFunction'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const MultipleArray = (props) => {
    const [isSlotPopup, setSlotPopup] = useState(false)
    const [tableBody, setTableBody] = useState([])
    const [fields, setfields] = useState([])
    const [reload, setReload] = useState(false)
    const [emptyInitial, setEmptyInitial] = useState([])
    const [validation, SetValidation] = useState({})
    const [initialValues, setInitialValues] = useState({
        is_deleted: 0,
        id: '',
        indexValue: -1,
    })

    //dynemicInitialValues
    useEffect(() => {
        if (fields && fields.fields && fields.fields.length) {
            let arr = initialValues
            fields.fields.map((buscat) => {
                arr[buscat.name] = ''
            })

            let handle = validationFunction(fields.fields, props.customValidation)

            SetValidation(handle)
            setInitialValues(arr)
            setEmptyInitial(arr)
        }
    }, [fields])

    const formikPopup = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validation),
        enableReinitialize: true,
        onSubmit: (values) => {
            if (values.indexValue >= 0) {
                let tempArr = tableBody
                let tempId = Number(values.indexValue)
                //delete values.indexValue
                tempArr[tempId] = values
                setTableBody([...tempArr])
            } else {
                setTableBody([...tableBody, values])
            }
            setSlotPopup(false)
        },
    })

    useEffect(() => {
        if (tableBody.length) {
            props.formik.setFieldValue(`${fields.name}`, {})
            props.formik.setFieldValue(`${fields.name}.${fields.subname}`, {})
            props.formik.setFieldValue(`${fields.name}.${fields.subname}.data`, {})
            props.formik.setFieldValue(`${fields.name}.${fields.subname}.data`, tableBody)
            formikPopup.resetForm()
        }
    }, [tableBody])

    useEffect(() => {
        setfields(props.data)
    }, [props.data])

    //singleListingHandling
    useEffect(() => {
        if (fields.name && props.formik.values[fields.name][fields.subname]) {
            setTableBody(props.formik.values[fields.name][fields.subname]['data'])
        }
        setReload(!reload)
    }, [props.formik.values, fields])

    //Edit
    const handleEdit = (indexValue) => {
        let tempEdit = props.formik.values[fields.name][fields.subname]['data'][indexValue]
        tempEdit.indexValue = indexValue
        setInitialValues(tempEdit)
        setSlotPopup(true)
    }

    //delete
    const handleDelete = (indexValue) => {
        let tempArr = [...tableBody]
        tempArr.splice(indexValue, 1)
        setTableBody([...tempArr])
        setReload(!reload)
    }

    //reset
    const resetForm = () => {
        setInitialValues(emptyInitial)
        formikPopup.handleReset()
        formikPopup.resetForm()
        setReload(!reload)
    }

    return (
        <>
            <>
                {reload}
                {tableBody?.length > 0 ? (
                    <>
                        <div className="slotLstWrpr text-left">
                            <Button
                                id="add_new"
                                className="adNwSltBtn"
                                onClick={() => {
                                    resetForm()
                                    setSlotPopup(true)
                                }}
                            >
                                <span className="material-icons-outlined">add_circle_outline</span>
                                {fields?.label ? fields.label : 'Add a new'}
                            </Button>

                            <div className="table-responsive dpTable mt-4">
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            {Object.keys(tableBody[0]).map((buscat) => {
                                                if (
                                                    buscat !== 'id' &&
                                                    buscat !== 'project_id' &&
                                                    buscat !== 'is_deleted' &&
                                                    buscat !== 'indexValue'
                                                ) {
                                                    return <th>{buscat.toUpperCase()}</th>
                                                }
                                            })}

                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableBody.map((data, index) => (
                                            <tr key={index}>
                                                {Object.keys(data).map((buscat) => {
                                                    if (
                                                        buscat !== 'id' &&
                                                        buscat !== 'project_id' &&
                                                        buscat !== 'is_deleted' &&
                                                        buscat !== 'indexValue'
                                                    ) {
                                                        return (
                                                            <td>{removeHTMLTags(data[buscat])}</td>
                                                        )
                                                    }
                                                })}

                                                <td>
                                                    <div className="d-flex">
                                                        <IconButton
                                                            className="icnBtn mr-2"
                                                            onClick={() => {
                                                                resetForm()
                                                                handleEdit(index)
                                                            }}
                                                        >
                                                            <span className="material-icons">
                                                                edit
                                                            </span>
                                                        </IconButton>
                                                        <IconButton
                                                            className="icnBtn"
                                                            onClick={() => handleDelete(index)}
                                                        >
                                                            <span className="material-icons">
                                                                delete
                                                            </span>
                                                        </IconButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Button
                            id="add_new_fields"
                            className="slotEntBtn"
                            onClick={() => {
                                setSlotPopup(true)
                            }}
                        >
                            {/* <img src="/assets/svg/createslot.svg" /> */}
                            <p> {fields?.label ? fields.label : 'Add a new'}</p>
                        </Button>
                        <div className="validationError text-left">{props.helperText}</div>
                    </>
                )}
            </>

            <Dialog
                className="addInfoModal"
                open={isSlotPopup}
                onClose={() => {
                    setSlotPopup(false)
                    resetForm()
                }}
                TransitionComponent={Transition}
            >
                <div>
                    <div className="infoModalTitle">
                        <h4>{fields?.label ? fields.label : ''}</h4>
                        <Button
                            id="multiple_array_close"
                            className="closeIcon"
                            onClick={() => {
                                resetForm()
                                setSlotPopup(false)
                            }}
                        >
                            <CloseIcon />
                        </Button>
                        {reload}
                    </div>
                    <form>
                        <div className="row">
                            {mapData({
                                formik: formikPopup,
                                data: fields?.fields ? fields.fields : [],
                            })}
                        </div>
                        <div className="d-flex align-items-center justify-content-end mt-4">
                            <SecondaryButton
                                id="multiArray_reset"
                                label="Cancel"
                                onClick={() => {
                                    resetForm()
                                    setSlotPopup(false)
                                }}
                            />
                            <PrimaryButton
                                id="multiArray_add"
                                btnSize="ml-2"
                                label="Add"
                                onClick={formikPopup.handleSubmit}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default MultipleArray
