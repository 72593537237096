import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import { ListItem } from '@material-ui/core'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import { useTheme } from '@material-ui/core/styles'
import i18next from 'i18next'

const LanguageSwitch = () => {
    const customCommonContext = useContext(CustomCommonContext)
    const { languages, currentLanguage, switchLanguage } = customCommonContext

    const [anchorEl, setAnchorEl] = useState(null)
    const [language, setLanguage] = useState({
        id: 0,
        label: 'EN',
        img: '/assets/svg/usFlag.svg',
        code: 'for_english',
    })
    const [languageArray, setLanguageArray] = useState([])
    const { i18n } = useTranslation()
    const theme = useTheme()

    const toCab = (data) => data.toString().charAt(0).toUpperCase() + data.slice(1)
    useEffect(() => {
        let temp = []
        if (languages)
            languages.forEach((element, index) => {
                let flagDetails = '/assets/svg/usFlag.svg'
                if (element === 'for_arabic') {
                    flagDetails = '/assets/svg/uaeFlag.svg'
                }
                temp.push({
                    id: index,
                    label: toCab(element.replace('for_', '')),
                    img: flagDetails,
                    code: element,
                })
            })
        setLanguageArray(temp)
    }, [languages])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event) => {
        setAnchorEl(null)
    }

    useEffect(() => {
        localStorage.setItem('currLanguage', currentLanguage)
        // console.log(localStorage.currLanguage, 'localStorage.currentLanguage')
    }, [document.body.dir])

    const changeLanguage = (data) => {
        setLanguage({
            id: data.id,
            label: data.label,
            img: data.img,
            code: data.code,
        })
        i18n.changeLanguage(data.code)
        switchLanguage(data.code)
        if (data.code === 'for_arabic') {
            document.body.dir = 'rtl'
            theme.direction = 'rtl'
        } else {
            document.body.dir = 'ltr'
            theme.direction = 'ltr'
        }
        handleClose()
    }

    return (
        <div className="languageSwitch">
            <Link
                to="#"
                aria-controls="simple-menu"
                aria-haspopup="true"
                // onClick={handleClick}
            >
                {/* <img src={language.img}></img> */}
                {language.label}
            </Link>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                className={`langDropMenu ${document.body.dir === 'rtl' && 'rtl'}`}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languageArray
                    .filter((x) => x.id !== language.id)
                    .map((data, index) => (
                        <ListItem
                            button
                            key={index}
                            className="languageMenu"
                            onClick={() => changeLanguage(data)}
                        >
                            <img src={data.img}></img>
                            {data.label}
                        </ListItem>
                    ))}
            </Menu>
        </div>
    )
}
export default LanguageSwitch
