// for common data
export const COMMON_VALUES = 'COMMON_VALUES'
export const SEARCH_VALUE = 'SEARCH_VALUE'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const US_STATE_VALUE = 'US_STATE_VALUE'
export const LOADERS = 'LOADERS'
export const UPLOADED_IMAGE = 'UPLOADED_IMAGE'
export const BID_HISTORY_VALUE = 'BID_HISTORY_VALUE'
export const CLEAR_BID_HISTORY = 'CLEAR_BID_HISTORY'
export const GET_STATIC_PAGE = 'GET_STATIC_PAGE'
export const GET_FOOTER_LINK = 'GET_FOOTER_LINK'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const TTWBID_HISTORY_VALUE = 'TTWBID_HISTORY_VALUE'
export const CLEAR_TTWBID_HISTORY = 'CLEAR_TTWBID_HISTORY'
export const CURRENCY_VALUE = 'CURRENCY_VALUE'
export const GET_PREVIOUS_DATA = 'GET_PREVIOUS_DATA'
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES'
export const GET_ALL_STATES = 'GET_ALL_STATES'
export const GET_ALL_CITIES = 'GET_ALL_CITIES'
export const SEARCH_AUCT_VALUE = 'SEARCH_AUCT_VALUE'
export const CLEAR_HEADER = 'CLEAR_HEADER'
export const GET_ALL_DYNAMIC_ARRAY = 'GET_ALL_DYNAMIC_ARRAY'
export const CLEAR_AUCT = 'CLEAR_AUCT'
