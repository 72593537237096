import React, { useState, useEffect, useContext } from 'react'
import CommonContext from '../../product/context/common/commonContext'
import { useDropzone } from 'react-dropzone'
import Loaders from '../../custom/components/molecules/Loaders'
import CustomDialog from '../../product/components/organisms/Dialog'
import { Badge, Button, Dialog } from '@material-ui/core'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import SecondaryButton from '../components/atoms/SecondaryButton'
import move from 'lodash-move'
import Reorder, {
    reorder,
    reorderImmutable,
    reorderFromTo,
    reorderFromToImmutable,
} from 'react-reorder'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Uploader = (props) => {
    let objectImage = props.objectImage === 1 ? true : false
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const commonContext = useContext(CommonContext)
    const { uploadImage, uploadedFiles, resetDropZone } = commonContext
    const formik = props.formik

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const auctionAvatar = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        onDrop: (acceptedFiles) => {
            setIsLoading(true)
            let arr = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            )
            var formData = new FormData()
            formData.append('folder', props.folder)
            formData.append('isCompress', props.isCompress)
            formData.append('quality', props.quality)
            formData.append('resultWidth', props.resultWidth)
            formData.append('resultHeight', props.resultHeight)

            arr.forEach((value, key) => {
                formData.append('file_upload', value)
            })
            uploadImage(formData, props.name)
        },
    })

    useEffect(() => {
        if (props.name === uploadedFiles.from && uploadedFiles.files.length) {
            if (props.multiple) {
                if (objectImage) {
                    let uploadedImage = []
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) => {
                            uploadedImage.push({
                                filename: uploaded.file_name,
                                title: '',
                                description: '',
                            })
                        })
                    formik.setFieldValue(props.name, [
                        ...formik.values[props.name],
                        ...uploadedImage,
                    ])
                } else {
                    let uploadedImage = []
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) => {
                            uploadedImage.push(uploaded.file_name)
                        })
                    formik.setFieldValue(props.name, [
                        ...formik.values[props.name],
                        ...uploadedImage,
                    ])
                }
            } else {
                if (objectImage) {
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) =>
                            formik.setFieldValue(props.name, [
                                { filename: uploaded.file_name, title: '', description: '' },
                            ]),
                        )
                } else {
                    uploadedFiles.files &&
                        uploadedFiles.files.map((uploaded) =>
                            formik.setFieldValue(props.name, [uploaded.file_name]),
                        )
                }
            }
            setIsLoading(false)
            setReload(!reload)
            resetDropZone(uploadedFiles.from)
        }
    }, [uploadedFiles])

    const getFileExtension = (file) => {
        let fileExtent = /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined
        if (fileExtent && fileExtent.length) {
            return fileExtent[0]
        } else {
            return ''
        }
    }

    const removeFile = (file, from) => {
        if (props.name === from) {
            let allFiles = formik.values[props.name].filter((fileUploaded) =>
                typeof fileUploaded == 'object'
                    ? fileUploaded.filename !== file.filename
                    : fileUploaded !== file,
            )
            formik.setFieldValue(props.name, allFiles)
            setReload(!reload)
        }
    }

    const viewFile = (file, from) => {
        if (props.name === from) {
            const newWindow = window.open(
                `${global.site_url}/uploads/${props.folder}/${
                    typeof file === 'object' ? file.filename : file
                }`,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        }
    }

    const validationArray = Yup.object({
        filename: Yup.string().required('Enter Filename'),
        title: Yup.string().required('Enter Title'),
        description: Yup.string().required('Enter Description'),
    })

    const formikObject = useFormik({
        initialValues: {
            filename: '',
            title: '',
            description: '',
            index: 0,
        },
        validationSchema: validationArray,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (toggleDialog) {
                let index = values.index

                if (typeof formik.values[props.name][index] === 'object') {
                    formik.values[props.name][index].title = values.title
                    formik.values[props.name][index].description = values.description
                } else {
                    formik.values[props.name][index] = {}
                    formik.values[props.name][index].filename = values.filename
                    formik.values[props.name][index].title = values.title
                    formik.values[props.name][index].description = values.description
                }

                setToggleDialog(false)
                formikObject.values.filename = ''
                formikObject.values.title = ''
                formikObject.values.description = ''
            } else {
                setToggleDialog(true)
            }
        },
    })

    const addDetails = (file, from) => {
        if (props.name === from) {
            let index = formik.values[props.name].findIndex((fileUploaded) =>
                typeof fileUploaded === 'object'
                    ? fileUploaded.filename === file
                    : fileUploaded === file,
            )

            let currentValue = formik.values[props.name][index]
            formikObject.setFieldValue('filename', file)
            formikObject.setFieldValue('index', index)

            if (typeof currentValue === 'object') {
                formikObject.setFieldValue('title', currentValue.title || '')
                formikObject.setFieldValue('description', currentValue.description || '')
            }

            setToggleDialog(true)
        }
    }

    const additionalDetails = {
        formik: formikObject,
        data: [
            {
                label: 'Title',
                type: 'text',
                placeholder: 'Enter the title',
                class: 'col-12',
                name: 'title',
            },
            {
                label: 'Description',
                placeholder: 'Enter the description',
                class: 'col-12',
                type: 'textarea',
                name: 'description',
            },
        ],
    }

    const isValidHttpUrl = (string) => {
        let url

        try {
            url = new URL(string)
        } catch (_) {
            return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
    }

    const onReorder = (e, from, to) => {
        let changedArray = move(formik.values[props.name], from, to)
        formik.setFieldValue([props.name], changedArray)
    }

    return (
        <>
            <div>
                <section>
                    <div {...auctionAvatar.getRootProps({ className: 'dropzone' })}>
                        <input {...auctionAvatar.getInputProps()} />
                        <span className="material-icons">{props.icon}</span>
                        <h4>{props.titleText}</h4>
                        <h6>{props.innerText}</h6>
                    </div>
                    <p className="validationError text-left">
                        {formik.touched[props.name] &&
                            formik.errors[props.name] &&
                            formik.errors[props.name]}
                    </p>
                    <aside className="thumbsContainer">
                        <>
                            {isLoading ? (
                                <Loaders isLoading={isLoading} />
                            ) : formik.values[props.name] && formik.values[props.name].length ? (
                                <>
                                    {props.reorder ? (
                                        <Reorder
                                            reorderId={props.titleText} // Unique ID that is used internally to track this list (required)
                                            reorderGroup={props.titleText} // A group ID that allows items to be dragged between lists of the same group (optional)
                                            // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                                            component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                                            className="reorderCnt" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                                            // placeholderClassName="check" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                            draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                            // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                            // holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                            touchHoldTime={250} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                                            mouseHoldTime={100} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                                            onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
                                            autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                                            disabled={false} // Disable reordering (optional), defaults to false
                                            disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                                            // placeholder={
                                            //     <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                                            // }
                                        >
                                            {formik.values[props.name].map((file, index) => (
                                                <div className="thumb" key={index}>
                                                    <div className="thumbInner">
                                                        {getFileExtension(file) === 'png' ||
                                                        getFileExtension(file) === 'jpg' ||
                                                        getFileExtension(file) === 'webp' ||
                                                        getFileExtension(file) === 'jpeg' ||
                                                        typeof file === 'object' ? (
                                                            <div className="thumbCnt">
                                                                <Badge>{index + 1}</Badge>
                                                                {isValidHttpUrl(file) ? (
                                                                    <img
                                                                        src={`${file}`}
                                                                        className="img"
                                                                        id={'thumb_' + index}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={`${
                                                                            global.site_url
                                                                        }/uploads/${
                                                                            props.isCompress
                                                                                ? props.folder +
                                                                                  '/compressed'
                                                                                : props.folder
                                                                        }/${
                                                                            typeof file === 'object'
                                                                                ? file.filename
                                                                                : file
                                                                        }`}
                                                                        className="img"
                                                                        id={'thumb_' + index}
                                                                    />
                                                                )}

                                                                <div className="fileActions">
                                                                    <span
                                                                        onClick={() =>
                                                                            removeFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="cancelBtn material-icons"
                                                                    >
                                                                        delete
                                                                    </span>
                                                                    <span
                                                                        onClick={() =>
                                                                            viewFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="viewBtn material-icons"
                                                                    >
                                                                        visibility
                                                                    </span>
                                                                    {objectImage ? (
                                                                        <span
                                                                            onClick={() =>
                                                                                addDetails(
                                                                                    file.filename,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="addBtn material-icons-outlined"
                                                                        >
                                                                            add_circle_outline
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="thumbCnt">
                                                                    <div className="defaultThumb">
                                                                        <span className="material-icons">
                                                                            {getFileExtension(
                                                                                file,
                                                                            ) === 'pdf'
                                                                                ? 'picture_as_pdf'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'doc' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'docx'
                                                                                ? 'description'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp4' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpeg'
                                                                                ? 'movie'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpga' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp3'
                                                                                ? 'volume_up'
                                                                                : 'description'}
                                                                        </span>
                                                                    </div>
                                                                    <div className="fileActions">
                                                                        <span
                                                                            onClick={() =>
                                                                                removeFile(
                                                                                    file,
                                                                                    props.name,
                                                                                    index,
                                                                                )
                                                                            }
                                                                            className="cancelBtn material-icons"
                                                                        >
                                                                            delete
                                                                        </span>
                                                                        <span
                                                                            onClick={() =>
                                                                                viewFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="viewBtn material-icons"
                                                                        >
                                                                            visibility
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </Reorder>
                                    ) : (
                                        <>
                                            {formik.values[props.name].map((file, index) => (
                                                <>
                                                    {typeof file === 'object' ? (
                                                        <div className="thumb" key={index}>
                                                            <div className="thumbInner">
                                                                {getFileExtension(file.filename) ===
                                                                    'png' ||
                                                                getFileExtension(file.filename) ===
                                                                    'jpg' ||
                                                                getFileExtension(file.filename) ===
                                                                    'webp' ||
                                                                getFileExtension(file.filename) ===
                                                                    'jpeg' ? (
                                                                    <div className="thumbCnt">
                                                                        <img
                                                                            src={`${global.site_url}/uploads/${props.folder}/${file.filename}`}
                                                                            className="img"
                                                                        />
                                                                        <div className="fileActions">
                                                                            <span
                                                                                onClick={() =>
                                                                                    removeFile(
                                                                                        file.filename,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="cancelBtn material-icons"
                                                                            >
                                                                                delete
                                                                            </span>
                                                                            <span
                                                                                onClick={() =>
                                                                                    viewFile(
                                                                                        file.filename,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="viewBtn material-icons"
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                            {objectImage ? (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        addDetails(
                                                                                            file.filename,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="addBtn material-icons-outlined"
                                                                                >
                                                                                    add_circle_outline
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="thumbCnt">
                                                                            <div className="defaultThumb">
                                                                                <span className="material-icons">
                                                                                    {getFileExtension(
                                                                                        file.filename,
                                                                                    ) === 'pdf'
                                                                                        ? 'picture_as_pdf'
                                                                                        : getFileExtension(
                                                                                              file.filename,
                                                                                          ) ===
                                                                                              'doc' ||
                                                                                          getFileExtension(
                                                                                              file.filename,
                                                                                          ) ===
                                                                                              'docx'
                                                                                        ? 'description'
                                                                                        : getFileExtension(
                                                                                              file.filename,
                                                                                          ) ===
                                                                                              'mp4' ||
                                                                                          getFileExtension(
                                                                                              file.filename,
                                                                                          ) ===
                                                                                              'mpeg'
                                                                                        ? 'movie'
                                                                                        : 'description'}
                                                                                </span>
                                                                            </div>
                                                                            <div className="fileActions">
                                                                                <span
                                                                                    onClick={() =>
                                                                                        removeFile(
                                                                                            file.filename,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="cancelBtn material-icons"
                                                                                >
                                                                                    delete
                                                                                </span>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        viewFile(
                                                                                            file.filename,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="viewBtn material-icons"
                                                                                >
                                                                                    visibility
                                                                                </span>
                                                                                {objectImage ? (
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            addDetails(
                                                                                                file.filename,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="addBtn material-icons-outlined"
                                                                                    >
                                                                                        add_circle_outline
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="thumb" key={index}>
                                                            <div className="thumbInner">
                                                                {getFileExtension(file) === 'png' ||
                                                                getFileExtension(file) === 'webp' ||
                                                                getFileExtension(file) === 'jpg' ||
                                                                getFileExtension(file) ===
                                                                    'jpeg' ? (
                                                                    <div className="thumbCnt">
                                                                        <img
                                                                            src={`${global.site_url}/uploads/${props.folder}/${file}`}
                                                                            className="img"
                                                                        />
                                                                        <div className="fileActions">
                                                                            <span
                                                                                onClick={() =>
                                                                                    removeFile(
                                                                                        file,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="cancelBtn material-icons"
                                                                            >
                                                                                delete
                                                                            </span>
                                                                            <span
                                                                                onClick={() =>
                                                                                    viewFile(
                                                                                        file,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="viewBtn material-icons"
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                            {objectImage ? (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        addDetails(
                                                                                            file,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="addBtn material-icons-outlined"
                                                                                >
                                                                                    add_circle_outline
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="thumbCnt">
                                                                            <div className="defaultThumb">
                                                                                <span className="material-icons">
                                                                                    {getFileExtension(
                                                                                        file,
                                                                                    ) === 'pdf'
                                                                                        ? 'picture_as_pdf'
                                                                                        : getFileExtension(
                                                                                              file,
                                                                                          ) ===
                                                                                              'doc' ||
                                                                                          getFileExtension(
                                                                                              file,
                                                                                          ) ===
                                                                                              'docx'
                                                                                        ? 'description'
                                                                                        : getFileExtension(
                                                                                              file,
                                                                                          ) ===
                                                                                              'mp4' ||
                                                                                          getFileExtension(
                                                                                              file,
                                                                                          ) ===
                                                                                              'mpeg'
                                                                                        ? 'movie'
                                                                                        : 'description'}
                                                                                </span>
                                                                            </div>
                                                                            <div className="fileActions">
                                                                                <span
                                                                                    onClick={() =>
                                                                                        removeFile(
                                                                                            file,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="cancelBtn material-icons"
                                                                                >
                                                                                    delete
                                                                                </span>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        viewFile(
                                                                                            file,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="viewBtn material-icons"
                                                                                >
                                                                                    visibility
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : null}
                        </>
                    </aside>
                </section>

                <Dialog
                    className="addCaptionModal"
                    open={toggleDialog}
                    onClose={changeDialogStatus}
                    TransitionComponent={Transition}
                >
                    <div>
                        <div className="infoModalTitle">
                            <h4>Add caption details</h4>
                            <Button
                                id="upload_add"
                                className="closeIcon"
                                onClick={changeDialogStatus}
                            >
                                <CloseIcon />
                            </Button>
                        </div>
                        <div className="row">{mapData(additionalDetails)}</div>
                        <div className="btnWrpr">
                            <SecondaryButton
                                id="dialogStatus"
                                onClick={changeDialogStatus}
                                label="Cancel"
                            />
                            <PrimaryButton
                                id="uploader_sub"
                                onClick={formikObject.handleSubmit}
                                type="button"
                                label={'Submit'}
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default Uploader
